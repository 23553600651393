import { useWeb3React } from '@web3-react/core'
import { connectorsByName, ConnectorNames } from '../utils/web3React'

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()

  const login = (conectorID = ConnectorNames.Injected) => {
    const connector = connectorsByName[conectorID]
    if (connector) {
      activate(connector, (error) => console.error(error))
    } else {
      console.error("Can't find connector", 'The connector config is wrong')
    }
  }

  return { login, logout: deactivate }
}

export default useAuth